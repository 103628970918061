import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';

import {
  SharedStyles,
  Container,
  CompChart,
} from '@matchbox-mobile/kenra-storybook';
const { StSectionTitle } = SharedStyles;

export default function ProductComparisonChart({ comparisonChart }) {
  const { allShopifyProduct } = useStaticQuery(graphql`
    query {
      allShopifyProduct {
        nodes {
          ...ShopifyProductFields
        }
      }
    }
  `);

  if (
    !comparisonChart ||
    !comparisonChart.items ||
    comparisonChart.items.length === 0
  ) {
    return null;
  }

  return (
    <section>
      <Container>
        <StSectionTitle styleBig>
          <h2>COMPARISON CHART</h2>
        </StSectionTitle>
      </Container>
      <CompChart
        items={comparisonChart.items.map((item, itemIndex) => {
          let shopifyProduct = allShopifyProduct.nodes.find(
            sp => sp.handle === item.product
          );

          return {
            colorDecor: itemIndex === 0 && '#000000',
            title: get(shopifyProduct, 'title'),
            url: `/product/${item.product}`,
            img: get(shopifyProduct, 'images[0].localFile.publicURL'),
            text: comparisonChart.rows
              .map((row, rowIndex) => {
                let title = row;
                let text = get(item, `rows[${rowIndex}]`);
                return `<p><strong>${title}</strong>${text}</p>`;
              })
              .join(''),
          };
        })}
      />
    </section>
  );
}
