import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Product from 'components/Product';

function getDropDownItems(shopifyProduct) {
  const { variants } = shopifyProduct;
  const SHOPS = {
    ulta: { img: '/images/ULTA.png', title: 'Ulta' },
    amazon: { img: '/images/Amazon.png', title: 'Amazon' },
    cosmoprof: { img: '/images/CosmoProf.png', title: 'CosmoProf' },
    saloncentric: { img: '/images/SalonCentric.png', title: 'SalonCentric' },
  };
  const dropDownItems = [];
  variants.forEach(v => {
    let items = [{ title: 'Your local salon', href: '/salon-locator' }];
    for (let shopKey in SHOPS) {
      let href = v.metafields?.find(m => m.key === shopKey)?.value;
      if (href) {
        items.push({ ...SHOPS[shopKey], href });
      }
    }
    dropDownItems.push({
      variant: v.shopifyId,
      items,
    });
  });
  return dropDownItems;
}

export default props => {
  let { shopifyProduct, contentfulProduct } = props.data;
  if (!shopifyProduct) return null;
  let dropDownItems = getDropDownItems(shopifyProduct);
  const singleVariant = dropDownItems.length === 1 ? true : false;
  return (
    <Layout>
      <Product
        shopifyProduct={shopifyProduct}
        contentfulProduct={contentfulProduct}
        dropDownItems={dropDownItems}
        singleVariant={singleVariant}
      />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    shopifyProduct(handle: { eq: $slug }) {
      ...ShopifyProductFields
    }
    contentfulProduct(shopifyProduct: { handle: { eq: $slug } }) {
      ...ContentfulProductFields
    }
  }
`;
