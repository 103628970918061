import React from 'react';
import get from 'lodash.get';

import { isKenraColor, isKenraPlatinum, getMetafield } from 'helpers/product';
import ProductSlider from 'blocks/ProductSlider';

import ProductDescriptionAccordion from './ProductDescriptionAccordion';
import ProductVideo from './ProductVideo';
import ProductComparisonChart from './ProductComparisonChart';

import {
  Spacing,
  Container,
  ProductDetail,
  ProductPicker,
  ProductDetailColors,
  ProductDetailColorInfo,
  ProductDetailTypes,
  ProductDetailMobileItems,
  DDButton,
  SharedStyles,
  StyleMemory,
  ColsArticles,
  ImageWithDesc,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionTitle } = SharedStyles;

const {
  StProductDetail,
  StProductDetailWrap,
  StProductDetailCol,
  StProductDetailType,
  StProductDetailMainImg,
  StProductDetailShopBtns,
} = ProductDetail;

class Product extends React.Component {
  state = {
    bigImageSelected: null,
    variantIdSelected: null,
    colorSelected: null,
    colorSelectedTemp: null,
  };

  componentDidMount() {
    this.init();
  }

  init() {
    let { shopifyProduct } = this.props;
    let { variants } = shopifyProduct;

    this.selectNewVariant(variants[0].shopifyId);
  }

  hasColors() {
    let colors = get(this.props, 'contentfulProduct.colors');
    return !!(Array.isArray(colors) && colors.length > 0);
  }

  // TODO product type should be reserved for the Shopify product type
  getType() {
    let { tags } = this.props.shopifyProduct;
    if (isKenraPlatinum(tags)) return 'Kenra Platinum';
    if (isKenraColor(tags)) return 'Kenra Color';
    return 'Kenra';
  }

  selectNewVariant(newVariantShopifyId) {
    let {
      shopifyProduct: { variants },
    } = this.props;

    let newVariant = variants.find(
      variant => variant.shopifyId === newVariantShopifyId
    );
    let newImageId = get(newVariant, 'image.id');
    // let newBigImageSelected = this.getBigImages().findIndex(
    //   image => image.id === newImageId
    // );

    this.setState({
      variantIdSelected: newVariantShopifyId,
      // bigImageSelected: newBigImageSelected,
    });
  }

  getCurrentVariant() {
    let { shopifyProduct } = this.props;
    let { variantIdSelected } = this.state;

    let variant = shopifyProduct.variants.find(
      v => v.shopifyId === variantIdSelected
    );
    return variant;
  }

  getCurrentColor() {
    if (!this.hasColors()) return null;

    let colors = get(this.props, 'contentfulProduct.colors');
    let { colorSelected, colorSelectedTemp } = this.state;
    if (colorSelected || colorSelectedTemp)
      return colors[
        colorSelectedTemp !== null ? colorSelectedTemp : colorSelected
      ];

    return colors[0];
  }

  getBigImages() {
    let { shopifyProduct } = this.props;
    let { bigImageSelected } = this.state;
    let shopifyImages = shopifyProduct.images;

    let bigImages = [
      ...shopifyImages.map(image => ({
        id: image.id,
        img: get(image, 'localFile.publicURL'),
        thmb: get(image, 'localFile.thumbnail.resize.src'),
      })),
    ];

    let variant = this.getCurrentVariant();
    if (variant) {
      let variantImageId = get(variant, 'image.id');
      if (!bigImages.find(image => image.id === variantImageId)) {
        bigImages.push({
          id: get(variant, 'image.id'),
          img: get(variant, 'image.localFile.publicURL'),
          thmb: get(variant, 'image.localFile.thumbnail.resize.src'),
        });
      }
    }

    let color = this.getCurrentColor();
    if (color) {
      let { faceImage } = color;
      if (faceImage) {
        bigImages.push({
          id: faceImage.id,
          img: get(faceImage, 'localFile.publicURL'),
          thmb: get(faceImage, 'localFile.thumbnail.resize.src'),
        });
      }
    }

    bigImages.forEach((bigImage, index) => {
      if (bigImageSelected === null) {
        return (bigImage.checked = index === 0);
      }
      if (bigImageSelected >= bigImages.length) {
        return (bigImage.checked = index === bigImages.length - 1);
      }

      bigImage.checked = index === bigImageSelected;
    });
    return bigImages;
  }

  getColorThumbnails() {
    let { contentfulProduct } = this.props;
    let { colorSelected } = this.state;
    let { colors } = contentfulProduct;

    if (!colors) return null;

    return colors.map((color, index) => {
      return {
        id: color.id,
        img: get(color, 'hairImage.localFile.color_preview.resize.src'),
        checked: colorSelected ? index === colorSelected : index === 0,
      };
    });
  }

  renderImages() {
    let bigImages = this.getBigImages();
    let color = this.getCurrentColor();

    return (
      <>
        <ProductPicker
          items={bigImages}
          onClickThmb={id => {
            let newBigImageSelected = null;
            bigImages.forEach((bigImage, index) => {
              if (bigImage.id === id) newBigImageSelected = index;
            });
            this.setState({ bigImageSelected: newBigImageSelected });
          }}
          onClickMainImg={() => {
            let bigImageSelected = this.state.bigImageSelected + 1;
            if (bigImageSelected > bigImages.length - 1) bigImageSelected = 0;
            this.setState({ bigImageSelected });
          }}
        />
        {color && (
          <StProductDetailMainImg style={{ backgroundColor: `auto` }}>
            <img src={get(color, 'hairImage.localFile.publicURL')} alt="" />
          </StProductDetailMainImg>
        )}
      </>
    );
  }

  renderColors() {
    if (!this.hasColors()) return null;

    let colorThumbnails = this.getColorThumbnails();
    let color = this.getCurrentColor();

    let { contentfulProduct } = this.props;
    let { colorSelected } = this.state;
    let { colors } = contentfulProduct;

    return (
      <>
        <ProductDetailColors
          items={colorThumbnails}
          onClickColor={id => {
            let newColorSelected = null;
            colorThumbnails.forEach((thumbnail, index) => {
              if (thumbnail.id === id) newColorSelected = index;
            });
            this.setState({
              colorSelected: newColorSelected,
              colorSelectedTemp: null,
            });
          }}
          onHoverColor={id => {
            let newColorSelected = null;
            colorThumbnails.forEach((thumbnail, index) => {
              if (thumbnail.id === id) newColorSelected = index;
            });
            this.setState({ colorSelectedTemp: newColorSelected });
          }}
          onLeaveColor={id => {
            this.setState({
              colorSelectedTemp: null,
            });
          }}
        />

        {color && (
          <ProductDetailColorInfo
            img={get(color, 'hairImage.localFile.color_preview.resize.src')}
            title={color.title}
            subTitle={color.subtitle}
          />
        )}
      </>
    );
  }

  renderVariants() {
    let { shopifyProduct } = this.props;
    let { variantIdSelected } = this.state;

    let { variants } = shopifyProduct;

    return (
      <ProductDetailTypes
        title="Available in"
        items={variants.map(variant => ({
          id: variant.shopifyId,
          name: 'variants-radio',
          label: variant.title,
          checked: variantIdSelected === variant.shopifyId,
        }))}
        onChange={e => this.selectNewVariant(e.target.id)}
      />
    );
  }

  renderSingleVariant() {
    let { shopifyProduct } = this.props;
    let { variants } = shopifyProduct;
    return (
      <ProductDetailTypes
        title="Available in"
        items={[
          {
            id: variants[0].shopifyId,
            name: 'variants-radio',
            label: variants[0].title,
            checked: true,
          },
        ]}
      />
    );
  }

  renderShopButton(dropDownItems) {
    let variant = this.getCurrentVariant();
    let items = [];
    if (variant) {
      const variantId = variant.shopifyId;
      const dropDown = dropDownItems.filter(v => {
        return v.variant === variantId;
      });
      if (dropDown.length > 0 && dropDown[0].items) {
        items = dropDown[0].items;
      }
    }
    return <DDButton items={items} />;
  }

  renderStyleMemory() {
    let { contentfulProduct } = this.props;
    let { styleMemory } = contentfulProduct;
    let title = null;
    if (styleMemory[0] && styleMemory[0].title) {
      title = styleMemory[0].title;
    }
    let items = [];
    if (styleMemory[0] && styleMemory[0].items) {
      items = styleMemory[0].items.map(item => {
        return {
          img: item.imageUrl,
          title: item.title,
        };
      });
    }
    if (!(items.length > 0)) {
      return null;
    }
    return (
      <Spacing>
        <section>
          <Container>
            <StSectionTitle styleBig>
              <h2>{title}</h2>
            </StSectionTitle>
          </Container>
          <StyleMemory colorDecor="#4a4a4ae6" items={items} />
        </section>
      </Spacing>
    );
  }

  formatPoints(points) {
    if (!points || points.length <= 0) {
      return null;
    }
    let formatted = '<ul>';
    points.forEach(point => {
      formatted += '<li>' + point + '</li>';
    });
    formatted += '</ul>';
    return formatted;
  }

  renderVsBlock() {
    let { contentfulProduct } = this.props;
    let { vsBlock } = contentfulProduct;
    let title = null;
    if (vsBlock[0] && vsBlock[0].title) {
      title = vsBlock[0].title;
    }
    let items = [];
    if (vsBlock[0] && vsBlock[0].items) {
      items = vsBlock[0].items.map(item => {
        return {
          img: item.imageUrl,
          title: item.title.toUpperCase(),
          text: this.formatPoints(item.points),
        };
      });
    }
    if (!(items.length > 0)) {
      return null;
    }
    return (
      <Spacing>
        <section>
          <Container>
            <StSectionTitle styleBig>
              <h2>{title}</h2>
            </StSectionTitle>
            <ColsArticles
              maxWidthMedium
              rows={[
                {
                  styleSizeBig: true,
                  styleColorGray: true,
                  items,
                },
              ]}
            />
          </Container>
        </section>
      </Spacing>
    );
  }

  renderTestBlock() {
    let { contentfulProduct } = this.props;
    let { testImage, testText, testTitle } = contentfulProduct;
    let text = get(testText, 'testText');
    return (
      <Spacing>
        <section>
          <Container>
            <StSectionTitle styleBig>
              <h2>{testTitle}</h2>
            </StSectionTitle>
          </Container>
          <ImageWithDesc
            maxWidthMedium
            styleSizeBig
            styleCols
            bg={get(testImage, 'localFile.publicURL') || null}
            text={text}
          />
        </section>
      </Spacing>
    );
  }

  render() {
    let {
      shopifyProduct,
      contentfulProduct,
      dropDownItems,
      singleVariant,
    } = this.props;

    if (!contentfulProduct) contentfulProduct = {};

    let { title, descriptionHtml } = shopifyProduct;
    let {
      description,
      descriptionAccordion,
      videoTitle,
      video,
      comparisonChart,
      vsBlock,
      styleMemory,
      testTitle,
      testImage,
    } = contentfulProduct;

    let youMayAlsoLikeProductsHandles = getMetafield(
      shopifyProduct,
      'ymal_products',
      null
    )?.split('|');

    return (
      <>
        <Spacing>
          <Container>
            <StProductDetail>
              <StProductDetailWrap>
                <StProductDetailCol>{this.renderImages()}</StProductDetailCol>
                <StProductDetailCol>
                  <div style={{ paddingBottom: '30px' }}>
                    <StProductDetailType>{this.getType()}</StProductDetailType>
                    <h1>{title}</h1>
                    {description && <h2>{description}</h2>}
                  </div>
                  {this.renderColors()}
                  {singleVariant
                    ? this.renderSingleVariant()
                    : this.renderVariants()}

                  <StProductDetailShopBtns>
                    {this.renderShopButton(dropDownItems)}
                  </StProductDetailShopBtns>

                  <ProductDescriptionAccordion
                    descriptionHtml={descriptionHtml}
                    descriptionAccordion={descriptionAccordion}
                    howItWorks={getMetafield(shopifyProduct, 'how_it_works')}
                    ingredients={getMetafield(shopifyProduct, 'ingredients')}
                  />
                </StProductDetailCol>
              </StProductDetailWrap>
            </StProductDetail>
          </Container>
        </Spacing>

        {video && video.youtubeLink && (
          <Spacing>
            <ProductVideo video={video} title={videoTitle} />
          </Spacing>
        )}

        {styleMemory && styleMemory.length > 0 && this.renderStyleMemory()}

        {vsBlock && vsBlock.length > 0 && this.renderVsBlock()}

        {testTitle && testImage && this.renderTestBlock()}

        {comparisonChart && (
          <Spacing>
            <ProductComparisonChart comparisonChart={comparisonChart} />
          </Spacing>
        )}

        {youMayAlsoLikeProductsHandles && (
          <Spacing>
            <ProductSlider
              title="You may also like"
              productHandles={youMayAlsoLikeProductsHandles}
            />
          </Spacing>
        )}
      </>
    );
  }
}

export default Product;
