import React from 'react';
import { documentToHtmlString } from './contentfulHelper';
import { Accordion } from '@matchbox-mobile/kenra-storybook';
import { multilineHTML } from 'helpers/text';

export default function ProductDescriptionAccordion(props) {
  let {
    descriptionHtml,
    descriptionAccordion,
    howItWorks,
    ingredients,
  } = props;

  let items = [];
  if (!descriptionAccordion) {
    items.push({
      opened: true,
      title: 'KEY FEATURES',
      text: descriptionHtml,
    });

    if (howItWorks) {
      items.push({
        opened: false,
        title: 'HOW TO USE',
        text: multilineHTML(howItWorks),
      });
    }

    if (ingredients) {
      items.push({
        opened: false,
        title: 'INGREDIENTS',
        text: multilineHTML(ingredients),
      });
    }
  } else {
    let string = documentToHtmlString(descriptionAccordion.json);
    let components = string
      .split('<h1>')
      .filter(i => i.length > 0)
      .map(i => i.split('</h1>'));
    let numberToOpen = 1;
    items = components.map((c, index) => {
      let title;
      let text;
      if (c.length === 2) {
        title = c[0];
        text = c[1];
      } else if (c.length === 1) {
        numberToOpen = 2;
        text = c[0];
      }
      return {
        opened: index < numberToOpen ? true : false,
        title,
        text,
      };
    });
  }

  return <Accordion items={items} />;
}
