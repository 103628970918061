import React from 'react';

import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';

import {
  SharedStyles,
  Container,
  VideoWithInfo,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionTitle } = SharedStyles;

export default function ProductVideo({ video, title }) {
  let youTubeId = getYouTubeId(video.youtubeLink);
  return (
    <section>
      <Container>
        <StSectionTitle styleBig>
          <h2>{(title && title.toUpperCase()) || 'HOW TO'}</h2>
        </StSectionTitle>
      </Container>
      <Container useLargeWidth>
        <VideoWithInfo
          img={getPreviewUrl(youTubeId)}
          useImgOverlay
          videoType="youtube"
          autoPlay={true}
          videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
        />
      </Container>
    </section>
  );
}
