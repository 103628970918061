export function isKenraColor(shopifyProductTags) {
  let colorTags = ['Color', 'Kenra Color Permanent', 'Kenra Color Demi'];
  for (let tag of colorTags) {
    if (shopifyProductTags.includes(tag)) return true;
  }

  return false;
}

export function isKenraPlatinum(shopifyProductTags) {
  return shopifyProductTags.includes('Platinum');
}

export function getMetafield(shopifyProduct, name, defaultValue = null) {
  if (!shopifyProduct || !Array.isArray(shopifyProduct.metafields))
    return defaultValue;

  let field = shopifyProduct.metafields.find(mf => mf.key === name);
  if (field) return field.value;

  return defaultValue;
}
